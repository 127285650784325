var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"no-body":""}},[_c('b-card-header',{staticClass:"pb-50"},[_c('h5',{staticClass:"mb-1"},[_vm._v("Filtros")])]),_c('b-card-body',[_c('b-row',[_c('b-col',{staticClass:"mb-1",attrs:{"cols":"12"}},[_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Nome ou descrição da solução finaceira..."},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search.apply(null, arguments)}},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('b-col',{staticClass:"mb-md-0 mb-2 text-right",attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"outline-primary","disabled":_vm.loading},on:{"click":function($event){$event.preventDefault();return _vm.search.apply(null, arguments)}}},[(_vm.loading)?_c('b-spinner',{attrs:{"small":""}}):_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SearchIcon"}}),_c('span',[_vm._v(" "+_vm._s(_vm.loading ? "Aguarde..." : "Pesquisar")+" ")])],1)],1)],1)],1)],1),_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',{staticClass:"mb-0"},[_vm._v("Exibir")]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"dir":"ltr","options":_vm.itemsPerPageOptions,"clearable":false},on:{"input":function($event){return _vm.search(_vm.currentPage)}},scopedSlots:_vm._u([{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Sem resultados ")]}}]),model:{value:(_vm.itemsPerPage),callback:function ($$v) {_vm.itemsPerPage=$$v},expression:"itemsPerPage"}}),_c('label',{staticClass:"mb-0"},[_vm._v("itens")])],1),(_vm.createFinancialSolutionEnabled)?_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[_c('b-button',{attrs:{"variant":"primary","to":{ name: 'financial-solution-save' }}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"PlusCircleIcon"}}),_c('span',{staticClass:"text-nowrap"},[_vm._v("Nova solução financeira")])],1)],1)]):_vm._e()],1)],1),_c('b-table',{staticClass:"position-relative",attrs:{"hover":"","items":_vm.items,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","show-empty":"","empty-text":"Não foram encontradas solução financeiras para esta busca","busy":_vm.loading},scopedSlots:_vm._u([{key:"cell(name)",fn:function(data){return [_c('div',{staticClass:"text-wrap"},[_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(data.item.name))])])]}},{key:"cell(description)",fn:function(data){return [_c('div',{staticClass:"text-wrap"},[_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(data.item.description))])])]}},{key:"cell(productName)",fn:function(data){return [_c('div',{staticClass:"text-wrap"},[_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(data.item.product.name))])])]}},{key:"cell(actions)",fn:function(data){return [(
            _vm.updateFinancialSolutionEnabled || _vm.deleteFinancialSolutionEnabled
          )?_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(_vm.updateFinancialSolutionEnabled)?_c('b-dropdown-item',{attrs:{"to":{
              name: 'financial-solution-save',
              params: { id: data.item.id },
            }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Editar")])],1):_vm._e(),(_vm.updateFinancialSolutionEnabled)?_c('b-dropdown-item',{attrs:{"to":{
              name: 'financial-solution-copy',
              params: { id: data.item.id },
            }}},[_c('feather-icon',{attrs:{"icon":"CopyIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Duplicar")])],1):_vm._e(),(_vm.deleteFinancialSolutionEnabled)?_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteItem(data.item)}}},[_c('feather-icon',{attrs:{"icon":"TrashIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Remover")])],1):_vm._e()],1):_vm._e()]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-start",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Exibindo "+_vm._s(_vm.pageItemsFrom)+" à "+_vm._s(_vm.pageItemsTo)+" de "+_vm._s(_vm.totalItems)+" itens")])]),_c('b-col',{staticClass:"d-flex align-items-center justify-content-center justify-content-sm-end",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalItems,"per-page":_vm.itemsPerPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},on:{"change":_vm.search},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }